export default {
  data() {
    return {
      slugName: '',
      slugId: '',
      isEventProduct: false,
      slug: null,
    }
  },
  methods: {
    setSlugData(id, name, slug = null, isEvent = false) {
      this.slugName = name
      this.slugId = id
      this.slug = slug
      this.isEventProduct = isEvent
    },
    productUrl() {
      const urlSlug = this.createSlug(this.slugId, this.slugName, this.slug)
      let result = `${this.$route.path}/${urlSlug}`
      if (this.isEventProduct) {
        const eventGroup = this.product.date ? 'events' : 'events-groups'
        result = `${this.$route.path}/${eventGroup}/${urlSlug}`
      }
      result = result.replace(/\/\//gm, '/')
      return result
    },
    productUrlFromMap() {
      let urlResult = ''
      const url = this.productUrl()
      urlResult = url.replace('/map', '') + this.createParamsStringFromObj(this.$route.query)

      return urlResult
    },
    addParamsFromObjToUrl(url, paramsObj) {
      if (!paramsObj) return url
      return url + this.createParamsStringFromObj(paramsObj)
    },
    createParamsStringFromObj(paramsObj) {
      if (!paramsObj || !Object.keys(paramsObj).length) return ''

      return '?' + new URLSearchParams(paramsObj).toString()
    },
    createSlug(id, name, slug = null) {
      let result = ''
      if (slug) {
        result = `${id}-${slug}`
      } else {
        const url = name?.replace(/ /g, '-')
          .split(',').join('')
          .split('/').join('-')
          .split(':').join('-')
          .split('(').join('')
          .split(')').join('')
          .split('---').join('-')
          .split('%').join('-')
          .split('&').join('-')
          .toLowerCase() || ''

        result = `${id}-${url}`
      }

      return result
    },
    goToProduct(event) {
      const isHotels = this.$route.fullPath.indexOf('hotels') !== -1
      if (!event.target.getAttribute('is-location')) {
        let routeData = this.$router.resolve({path: `${this.productUrl()}`, query: isHotels ? this.$route.query : this.changeQuery(true)})
        window.open(routeData.href, '_blank')
      }
    },
    convertParamsToString(query){
      const param = Object.keys(query)
        .map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
          )
        })
        .join('&')
      return param
    },
    changeQuery(toReturn) {
      let queryParams = Object.assign({}, this.$route.query)

      for (let parameter in queryParams) {
        if (toReturn) {
          if (parameter.indexOf('return') === -1) {
            queryParams['return' + parameter] = queryParams[parameter]
            delete queryParams[parameter]
          }
        } else {
          if (parameter.indexOf('return') > -1) {
            queryParams[parameter.replace('return', '')] = queryParams[parameter]
            delete queryParams[parameter]
          }
        }
      }

      return queryParams
    }
  }
}
